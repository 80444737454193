export function scrollToElement(el: HTMLElement, direction: number) {
	const duration = 2000;
	// старт анимации прокручивания страницы
	const start = new Date().getTime();

	const pageHeight = Math.max(
		document.body.scrollHeight,
		document.documentElement.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.offsetHeight,
		document.body.clientHeight,
		document.documentElement.clientHeight
	);
	const fn = function () {
		// текущее положение верхней границы контейнера с учётом высоты шапки с меню
		// при прокрутке контейнер не должен заходить под шапку
		let heightHeader = 0;

		if (document.querySelector('.header')) {
			heightHeader = document.querySelector('.header')!.getBoundingClientRect().height;
		}

		// console.log(heightHeader);
		const top = el.getBoundingClientRect().top - heightHeader - 50;
		// время прошедшее от начала прокрутки страницы
		const now = new Date().getTime() - start;
		// на сколько должна быть прокручена страница
		let result = Math.round((top * now) / duration);

		// корректируем значение 'result', чтобы контейнер остановился
		// точно по нижней границе шапки
		result = result > direction * top ? top : result == 0 ? direction : result;

		// определяем есть необходимость прокручивать страницу дальше или нет
		// применение этого условия необходимо, когда высота последнего контейнера
		// меньше высоты экрана и верхняя граница контейнера физически не может
		// достигнуть верхней границы экрана, в нашей вёрстке - это container 6
		// window.pageYOffset - текущая прокрутка страницы
		// document.documentElement.clientHeigh - размер видимой части окна
		if (direction * top > 0 && pageHeight - window.pageYOffset > direction * document.documentElement.clientHeight) {
			window.scrollBy(0, result);
			// рекурсивно запускаем функцию анимации прокрутки страницы
			requestAnimationFrame(fn);
		} else {
			scrollToElement.listners.forEach((fn) => {
				fn(el);
			});
		}
	};

	// старт прокрутки страницы
	requestAnimationFrame(fn);
}

type scrollToElementAnhorCallback = (anhor: HTMLElement) => void;

scrollToElement.listners = [] as scrollToElementAnhorCallback[];

scrollToElement.onEnd = function (fn: scrollToElementAnhorCallback) {
	scrollToElement.listners.push(fn);
};

interface scroll {
	onEnd(fn: scrollToElementAnhorCallback): void;
}

export function scrollToElementAnhor(anhor: HTMLElement) {
	if (!anhor) {
		return false;
	}
	let heightHeader = 0;

	if (document.querySelector('.header')) {
		heightHeader = document.querySelector('.header')!.getBoundingClientRect().height;
	}
	const startY = anhor.getBoundingClientRect().top + heightHeader;
	// направление прокрутки зависит от положения верхней границы контейнера
	// относительно верхней границы окна браузера
	// нужный нам контейнер может находится выше или ниже окна браузера,
	// соответственно, страницу нужно прокручивать вверх или вниз
	// для этого необходимо вычислить коэффициент, от которого будет зависеть
	// направление прокрутки
	const direction = startY < 0 ? -1 : startY > 0 ? 1 : 0;

	// верхняя граница контейнера, к которому собираемся перейти, находится
	// сразу под шапкой - нет необходимости прокручивать страницу
	if (direction == 0) {
		return;
	}
	// запускаем функцию прокручивания страницы до выбранного контейнера
	scrollToElement(anhor, direction);
}

scrollToElementAnhor.onEnd = function (fn: scrollToElementAnhorCallback) {
	scrollToElement.onEnd(fn);
};
