import { scrollToElement } from './scrollToAnchor';
import { setActiveSelect } from '~libs/select';

export function toggleTextarea(status: null | boolean = null) {
	try {
		let expandingTheText = document.querySelector('.expanding-the-text') as HTMLElement;
		let buttonForExpandingTheText = document.querySelector('.button-for-expanding-the-text') as HTMLElement;
		let iconPlus = buttonForExpandingTheText.querySelector('.plus-button i')!;
		if (status === null) {
			status = iconPlus.classList.contains('fa-plus');
		}
		iconPlus.classList.toggle('fa-plus', status === false);
		iconPlus.classList.toggle('fa-minus', status === true);
		expandingTheText.classList.toggle('active', status);
		buttonForExpandingTheText.querySelector('span')!.innerHTML = !status ? 'Добавить текст' : 'Сообщение';
	} catch (error) {
		console.error(error);
	}
}

export function writeMainForm({ text, theme }: { text: string; theme: string | null }) {
	try {
		toggleTextarea(true);
		const mainForm = document.getElementById(ID) as HTMLTextAreaElement;
		mainForm.value = text;

		const select: HTMLElement | null = document.querySelector(`.select[data-select="main-form"]`);
		if (select) {
			const selectItem: HTMLLIElement | null = select.querySelector(`.select__openlist li[data-key="${theme}"`);
			if (selectItem) {
				selectItem.click();
			}
		}
	} catch (error) {
		console.error(error);
	}
}
const ID = 'main-form';

function http_build_query(obj: Record<string, number | string>) {
	return Object.entries(obj)
		.map(([key, value]) => {
			return `${key}=${encodeURI(value.toString())}`;
		})
		.join('&');
}
function checkForm(): boolean {
	return document.querySelectorAll('#' + ID).length > 0;
}

export function scrollToMainForm() {
	const relationSection = document.getElementById('relation-section') as HTMLElement;
	const startY = relationSection.getBoundingClientRect().top;
	scrollToElement(relationSection, startY < 0 ? -1 : startY > 0 ? 1 : 0);
}

export function blankMainForm($el: HTMLElement): boolean {
	const { text, theme } = $el.dataset;
	if (!checkForm()) {
		window.location.href =
			`/?` +
			http_build_query({
				text: text ?? '',
				form: 'yes',
				theme: theme ?? ''
			});
		return false;
	}

	scrollToMainForm();
	setTimeout(function () {
		writeMainForm({ text: text ?? '', theme: theme ?? null });
	}, 1000);

	return false;
}

export function parseQuery(url: URL) {
	const queriesEntries = decodeURIComponent(url.search.length > 0 ? url.search.slice(1) : url.search)
		.split('&')
		.map((str) => {
			const [key, ...value] = str.split('=');
			return [key, decodeURI(value.join(''))];
		});

	return Object.fromEntries(queriesEntries);
}

export function checkActionUrl(href: URL) {
	const keys = Object.keys(parseQuery(href));
	switch (true) {
		case keys.includes('form'):
			return true;

		default:
			break;
	}
	return false;
}

if (checkActionUrl(new URL(window.location.href))) {
	const { form, text, theme } = parseQuery(new URL(window.location.href));

	if (checkForm() && form && form === 'yes') {
		setTimeout(function () {
			scrollToMainForm();
			writeMainForm({ text: text ?? '', theme });
		}, 2000);
	}
}

window.blankMainForm = blankMainForm;
