import '~style/style.scss';
import Vue, { CreateElement, VNode } from 'vue';
import Swiper  from 'swiper';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper/modules'
import 'swiper/swiper-bundle.css';
import { Office, OfficeController } from './libs/OfficeController';
import placemarkIconUrl from '../css/images/geo-icon-for-map.svg';
import IMask from 'imask';
declare global {
	interface Window {
		mainMenu: IMenuApi;
	}
}
import { blankMainForm, toggleTextarea } from './libs/formHelper';
import { scrollToElement } from './libs/scrollToAnchor';
import { IMenuApi } from './libs/mainMenu';
import { addEventListenerSelect } from './libs/select';
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
declare global {
	interface Window {
		ymaps: any;
		services: any;
		OfficeController: { new (): OfficeController };
		blankMainForm: ($el: HTMLElement) => boolean;
		toGalleryScroll: ($el: HTMLElement) => boolean;

		scrollToPrice: (target: string) => boolean;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	let calendar: any;
	const officeController = new OfficeController();
	const mediaQueryTablet = window.matchMedia('(max-width: 1235px)');

	if (document.getElementById('eventsOfCalendar')) {
		const CalendarOfPageInCalendarOfEvents = () => import('./components/CalendarOfPageInCalendarOfEvents.vue');
		new Vue({
			el: '#eventsOfCalendar',
			render: (h: CreateElement): VNode => h(CalendarOfPageInCalendarOfEvents)
		});
	}
	if (document.getElementById('calendar')) {
		console.log(1)
		import('./components/Calendar.vue').then(({ default: EventCalendar }) => {
			calendar = new Vue({
				el: '#calendar',
				render: (h: CreateElement): VNode => h(EventCalendar)
			});
			// @ts-ignore
			calendar.officeAddress = officeController.getActiveOffice().address[2];
			// @ts-ignore
		});
	}

	Promise.all([
		// @ts-ignore
		import('./libs/select'),
		import('./libs/formHelper'),
		import('./libs/galleryScroll'),
		import('./libs/scrollToPrice'),
		import('./libs/mainMenu')
	]);

	if (document.body.classList.contains('page-template-main')) {
		new Swiper('.container-for-gallery-and-arrows-gallery .main__gallery', {
			navigation: {
				nextEl: '.container-for-gallery-and-arrows-gallery .swiper-button-next',
				prevEl: '.container-for-gallery-and-arrows-gallery .swiper-button-prev'
			},
			// autoplay: {
			// 	delay: 3000
			// },
			slidesPerView: 1,
			spaceBetween: 0,
			// height: 463,
			width: null,
			loop: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			breakpoints: {
				// 1235: {
				// 	height: undefined,
				// 	width: undefined,
				// }
			}
		});

		if (document.querySelectorAll('.addresses-item').length >= 1) {
			function searchActiveIndexOfSlide() {
				return (
					Array.from<HTMLDivElement>(document.querySelectorAll('.addresses-item')).findIndex((item) => {
						return +(item.dataset.officeId ?? 1) === officeController.getActiveOffice().id;
					}) ?? 0
				);
			}
		}

		// swiperAddresses.slideTo();
		// console.log(swiperAddresses.activeIndex);
		// swiperAddresses.activeIndex = activeIndex;

		// arrowsInRelation!.classList.add('active');
		// }
		function init() {
			if (document.getElementById('map')) {
				const { offices } = officeController;
				let maps = new window.ymaps.Map('map', {
					center: officeController.getActiveOffice().coords,
					zoom: 19
				});
				let addressesSwiper: null | Swiper = null;
				function getOfficeId(item: HTMLDivElement): number {
					return +(item.dataset.officeId ?? 1);
				}
				function getCoordsElement(item: HTMLDivElement): number[] {
					const officeId = getOfficeId(item);
					const { coords } = offices.find(({ id }) => id === officeId) as Office;
					return coords;
				}

				Array.from<HTMLDivElement>(document.querySelectorAll('.addresses-item')).forEach(
					(item: HTMLDivElement, index: number) => {
						const placemark = new window.ymaps.Placemark(
							getCoordsElement(item),
							{
								// iconLayout: 'default#image',
							},
							{
								iconLayout: 'default#image',
								iconImageHref: placemarkIconUrl as string,
								iconImageSize: [48, 48]
							}
						);

						placemark.events.add('click', () => {
							item.click();
						});
						maps.geoObjects.add(placemark);
						item.onclick = () => {
							Array.from<HTMLDivElement>(document.querySelectorAll('.addresses-item')).forEach((el: HTMLDivElement) => {
								el.classList.toggle('active', false);
							});
							(item.closest('.addresses-item') as HTMLElement).classList.toggle('active', true);

							if (addressesSwiper) {
								addressesSwiper.slideTo(
									Array.from(document.querySelectorAll('.addresses-item')).findIndex((el) => {
										return el.classList.contains('active');
									})
								);
							}
							maps.panTo(getCoordsElement(item));
							officeController.setOffice(getOfficeId(item))



						};
						const officeId = getOfficeId(item);
						if (officeId === officeController.getActiveOffice().id) {
							item.click();
						}
					}
				);

				function initSliderAddresses() {
					if (window.innerWidth < 1235) {
						if (addressesSwiper === null) {
							const swiperContainer = document.querySelector(
								'.map__addresses.addresses .swiper-container'
							) as HTMLDivElement;
							addressesSwiper = new Swiper(swiperContainer, {
								spaceBetween: 10,
								// loop: true,
								slidesPerView: 1,
								observeSlideChildren: true,
								observeParents: true,
								observer: true,
								watchSlidesVisibility: true,

								initialSlide: Array.from(swiperContainer.querySelectorAll('.addresses-item')).findIndex((el) => {
									return el.classList.contains('active');
								})
							});
							addressesSwiper.on('transitionEnd', (swiper) => {
								const office: HTMLDivElement | null = swiper.$el[0].querySelector(
									'.swiper-slide-visible.swiper-slide-active'
								);
								if (office) {
									office.click();
								}
							});
						} else {
							if (addressesSwiper !== null) {
								addressesSwiper.destroy();
								addressesSwiper = null;
							}
						}
					}
				}

				initSliderAddresses();
				window.addEventListener('resize', initSliderAddresses);
			}
		}

		window.ymaps.ready(init);

		(function () {
			if (document.getElementById('phone-mask')) {
				let inputPhoneMask = document.getElementById('phone-mask')!;
				IMask(inputPhoneMask, {
					mask: '+{7} (000) 000-00-00'
				});
			}
		})();

		// скрипт для страницы с постом
		(function () {
			if (document.querySelector('.post__text')) {
				let postText = document.querySelector('.post__text');

				postText!.querySelectorAll('p').forEach((paragraph) => {
					if (paragraph.innerHTML == '') paragraph.remove();
				});
			}
		})();

		(function () {
			let buttonForExpandingTheText = document.querySelector('.button-for-expanding-the-text')!;
			let expandingTheText = document.querySelector('.expanding-the-text')!;

			if (buttonForExpandingTheText && expandingTheText) {
				buttonForExpandingTheText.addEventListener('click', () => {
					toggleTextarea();
				});
			}

			// if (checkActionUrl(new URL(window.location.href))) {
			// 	const { form, text } = parseQuery(new URL(window.location.href));
			// 	const textarea = document.getElementById('main-form') as HTMLTextAreaElement;
			// 	if (textarea && form && form === "yes") {
			// 		writeMainForm(textarea, { text });
			// 	}
			// }
		})();
	}

	Array.from<HTMLDivElement>(document.querySelectorAll('.space-description .space-description__gallery')).forEach(
		(item) => {
			let swiper: Swiper | null = null;
			let width = 0;
			function createSwiper() {
				width = item.getBoundingClientRect().width;
				swiper = new Swiper(item.querySelector('.swiper-container') as HTMLDivElement, {
					effect: 'slide',
					observeSlideChildren: true,
					observeParents: true,
					observer: true,
					// width: 529,
					spaceBetween: 10,
					watchSlidesVisibility: true,
					preloadImages: false,
					lazy: {
						loadPrevNext: true,
						loadPrevNextAmount: 1
					},
					navigation: {
						nextEl: item.querySelector('.swiper-button-next') as HTMLDivElement,
						prevEl: item.querySelector('.swiper-button-prev') as HTMLDivElement
					},
					pagination: {
						el: item.querySelector('.swiper-pagination') as HTMLDivElement,
						type: 'bullets',
						clickable: true
						// dynamicBullets: true
					},
					fadeEffect: {
						crossFade: true
					},
					// slidesOffsetAfter: 20,
					// slidesOffsetBefore: 20,
					// width: width,
					// width: null,
					breakpoints: {
						1300: {
							effect: 'fade',
							width: 529,
							slidesOffsetAfter: 0,
							slidesOffsetBefore: 0
						}
					}
				});
			}
			createSwiper();

			// function rebuildSlider() {
			// 	if (item.getBoundingClientRect().width !== 0 && width !== item.getBoundingClientRect().width) {
			// 		if (swiper) swiper.destroy(true);
			// 		createSwiper();
			// 	}
			// }
			// window.addEventListener('resize', rebuildSlider);
			// item.addEventListener('space-description-gallery', rebuildSlider);
		}
	);

	function tabsActiveScroll() {
		Array.from<HTMLDivElement>(document.querySelectorAll('.tabs')).forEach((tabs) => {
			const wrapperScroll = tabs.querySelector('.tabs__buttons');
			if (!wrapperScroll) return;
			const buttonActive = wrapperScroll.querySelector('.active');

			if (!buttonActive) return;

			const { width: widthWrapper } = wrapperScroll.getBoundingClientRect();
			const { left, width } = buttonActive.getBoundingClientRect();
			const leftWrapper = wrapperScroll.getBoundingClientRect().left + window.scrollX;
			let scrollLeft = left - leftWrapper + wrapperScroll.scrollLeft;
			scrollLeft = scrollLeft - (widthWrapper - width) / 2;
			wrapperScroll.scrollTo({ left: scrollLeft > 0 ? scrollLeft : 0, behavior: 'smooth' });
		});
	}
	window.addEventListener('resize', tabsActiveScroll);
	tabsActiveScroll();

	Array.from<HTMLDivElement>(document.querySelectorAll('.tabs-space, .tabs-price')).forEach((tabs) => {
		Array.from<HTMLButtonElement>(tabs.querySelectorAll('.tabs__buttons button')).forEach((button, index) => {
			button.addEventListener('click', () => {
				const target = button.dataset.name as string;
				const tabContent = tabs.querySelector(`[data-space="${target}"]`) as HTMLDivElement;
				tabContent.classList.toggle('active', true);

				button.classList.toggle('active', true);
				Array.from<HTMLDivElement>(tabs.querySelectorAll('[data-space]')).forEach((content) => {
					if (tabContent !== content) {
						content.classList.toggle('active', false);
					}
				});
				Array.from<HTMLButtonElement>(tabs.querySelectorAll('[data-name]')).forEach((content) => {
					if (button !== content) {
						content.classList.toggle('active', false);
					}
				});
				tabsActiveScroll();
			});
			if (index === 0) {
				button.click();
			}
		});
	});

	if (Array.from<HTMLDivElement>(document.querySelectorAll('.tabs-space, .tabs-price')).length > 0) {
		function createSelect() {
			const select = document.createElement('div');
			const container = document.createElement('div');
			const list = document.createElement('div');

			select.classList.add('select');
			container.classList.add('select__container');
			list.classList.add('select__openlist');

			select.append(container);
			select.append(list);

			return {
				select,
				append(item: HTMLLIElement) {
					list.append(item);
				},
				init() {

					if (list.children.length > 0) {
						const first = list.children[0].innerHTML;
						const span = document.createElement('span');
						span.innerHTML = first;
						container.append(span);

						container.append(document.createElement('br'));
						const i = document.createElement('i');
						i.classList.add('fas', 'fa-chevron-up');
						container.append(i);
						addEventListenerSelect(select);
					}

				}
			};
		}

		interface SpaceInformation {
			options: string[];
			prices: string[];
			actions: string[];
		}
		for (const table of Array.from<HTMLTableElement>(document.querySelectorAll('.services-and-prices table'))) {
			const rows = table.rows;
			const plansTitle = Array.from(rows[0].cells)
				.slice(1)
				.map((td) => {
					return td.innerText;
				});
			const rowsContent = Array.from(rows).slice(1);
			const plansEntries = plansTitle
				.map((title) => [title, { options: [], prices: [], actions: [] }] as [string, SpaceInformation])
				.map(([title, items], key) => {
					for (const row of rowsContent) {
						const cells = Array.from(row.cells);
						if (cells[key + 1].querySelectorAll('.red-dot').length) {
							items.options.push(cells[0].innerHTML.trim());
						}
						if (row.classList.contains('services-and-prices__prices')) {
							if (cells[0].innerHTML.trim().length > 0) {
								items.actions.push(cells[0].innerHTML.trim());
							}
							if (cells[key + 1].innerHTML.trim().length > 0) {
								if (Array.from(cells[key + 1].querySelectorAll('.line-red')).length < 1) {
									items.prices.push(cells[key + 1].innerHTML.trim());
								}
							}
						}
					}
					return [title, items];
				});
			const plans: Record<string, SpaceInformation> = Object.fromEntries(plansEntries);
			const content = document.createElement('div');
			content.classList.add('mobile-content-prices');
			const parentOffice = table.closest('[data-office-id]') as HTMLDivElement;
			const officeId = parseInt(parentOffice.dataset.officeId ?? '', 10);
			const parentSpace = table.closest('[data-space]') as HTMLDivElement;
			const space = parentSpace.dataset.space;

			const { select, append, init } = createSelect();
			select.dataset.select = `office-${officeId}:${space}`;

			for (const plan in plans) {
				if (Object.prototype.hasOwnProperty.call(plans, plan)) {
					const value = plans[plan];
					const table = document.createElement('table');
					table.classList.add('mobile-table-price');
					table.dataset.from = `office-${officeId}:${space}:${plan}`;
					value.options
						.map((item) => {
							const td = document.createElement('td');
							const tr = document.createElement('tr');
							td.innerHTML = item;
							tr.append(td);
							return tr;
						})
						.forEach((tr) => {
							table.append(tr);
						});

					const priceTr = document.createElement('tr');
					const priceTd = document.createElement('td');
					priceTr.append(priceTd);
					priceTr.classList.add('mobile-services-and-prices__prices');
					value.prices.forEach((item) => {
						const div = document.createElement('div');
						div.innerHTML = item;
						priceTd.append(div);
					});
					table.append(priceTr);
					content.append(table);
					const selectOption = document.createElement('li');
					selectOption.innerHTML = plan;
					selectOption.dataset.plan = plan;
					append(selectOption);
				}
			}
			init();
			content.prepend(select);
			table.after(content);
			const actions = document.createElement('div');
			actions.classList.add('mobile-services-and-prices__actions');

			if (	Object.values(plans).length > 0) {
				Object.values(plans)[0].actions.forEach((item) => {
					const div = document.createElement('div');
					div.innerHTML = item;
					actions.append(div);
				});
				content.append(actions);

				(content.children[1] as HTMLDivElement).style.display = 'table';
			}


			// for (const plan of plans) {
			// 	const value = plans[plan];
			// }

			// console.log(plans);
			// const parentOffice = table.closest('[data-office-id]') as HTMLDivElement;
			// console.log(Object.fromEntries(plansEntries), parentSpace.dataset.space, );
		}
	}

	Array.from<HTMLButtonElement>(document.querySelectorAll('.services-and-prices .tabs__buttons button')).forEach(
		(button) => {
			button.addEventListener('click', () => {
				const space = button.dataset.name as string;
				if (Object.keys(officeController.services).includes(space)) {
					calendar.$children[0].hide = false;
					calendar.$children[0].roomInServicesAndPrices(space, officeController.getActiveOffice());
				} else {
					calendar.$children[0].hide = true;
				}
			});
		}
	);

	document.addEventListener('scroll', handleScroll, {
		passive: true
	});
	handleScroll();

	function handleScroll() {
		document.body.classList.toggle('scroll', window.pageYOffset > 10);

		let buttonsWhenScrolling = document.querySelector('.buttons-when-scrolling') as HTMLDivElement;
		if (buttonsWhenScrolling) {
			let heightOfTheFirstSection = 200;
			if (window.pageYOffset >= heightOfTheFirstSection) {
				buttonsWhenScrolling.style.opacity = '1';
			} else if (window.pageYOffset <= heightOfTheFirstSection) {
				buttonsWhenScrolling!.style.opacity = '0';
			}
		}
	}

	function parseAnchorLink({ hash }: URL): HTMLElement | null {
		const space = decodeURI(hash).slice(1);
		const regex = /c([0-9]{1})-([a-z]+)-(.*)/;
		const match = regex.exec(space);

		if (match) {
			const [, idOffice, section, target] = match;
			const tab: HTMLElement | null = document.querySelector(
				`.tabs-${section}[data-office-id="${idOffice}"] [data-name="${target}"]`
			);
			if (tab) {
				return tab;
			}
		}
		return null;
	}

	Array.from<HTMLAnchorElement>(
		document.querySelectorAll(
			'#header .header-container .menu-item a, #header .menu-mobile .menu-item a,  .footer .menu-item a'
		)
	).forEach((link) => {
		if (link.href) {
			const tab = parseAnchorLink(new URL(link.href));
			if (tab) {
				link.addEventListener('click', (event) => {
					event.preventDefault();
					const startY = tab.getBoundingClientRect().top;
					scrollToElement(tab, startY < 0 ? -1 : startY > 0 ? 1 : 0);
					tab.click();
					window.mainMenu.close();
				});
			} else {
				const anchor = decodeURI(new URL(link.href).hash).slice(1);
				const target = document.getElementById(anchor);
				if (target) {
					link.addEventListener('click', (event) => {
						event.preventDefault();
						const startY = target.getBoundingClientRect().top;
						scrollToElement(target, startY < 0 ? -1 : startY > 0 ? 1 : 0);
						target.click();
					});
				}
			}
		}
	});

	(function () {
		const tab = parseAnchorLink(new URL(window.location.href));
		if (tab) {
			const startY = tab.getBoundingClientRect().top;
			scrollToElement(tab, startY < 0 ? -1 : startY > 0 ? 1 : 0);
			tab.click();
		} else {
			const anchor = decodeURI(new URL(window.location.href).hash).slice(1);
			const target = document.getElementById(anchor);
			if (target) {
				const startY = target.getBoundingClientRect().top;
				scrollToElement(target, startY < 0 ? -1 : startY > 0 ? 1 : 0);
			}
		}
	})();

	(function () {
		(document.getElementById('scrolling-top') as HTMLButtonElement).addEventListener('click', () => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		});
		(document.getElementById('scrolling-form') as HTMLButtonElement).addEventListener('click', () => {
			blankMainForm(document.createElement('a'));
		});
	})();

	(function () {
		let sectionNewsInMainPage = document.querySelector('.news .swiper-container');

		if (sectionNewsInMainPage && mediaQueryTablet.matches) {
			new Swiper(sectionNewsInMainPage as HTMLDivElement, {
				breakpoints: {
					1235: {},
					320: {
						slidesPerView: 'auto'
					}
				}
			});
		}
	})();
});
