import Cookies from 'js-cookie'

import { css } from './util';

export interface EventChangeOffice {
	office: string;
}

export interface Office {
	id: number;
	name: string;
	address: string[];
	phone: string;
	coords: number[];
}

function getPhoneFormat(phone: string, output: 'string' | 'number' = 'string') {
	if (output === 'string') {
		const match = /(7)([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/.exec(phone);
		if (!match) return '';
		return `+${match[1]} ${match[2]} <span>${match[3]}-${match[4]}-${match[5]}</span>`;
	} else {
		return phone.replace(/~\D+~/, '');
	}
}

export interface Service {
	name: string;
	key: string;
}

export class OfficeController {
	private static instance: OfficeController | undefined;

	public offices: Office[] = [];

	private _activeOffice: number = 0;
	public services: Record<string, Service> = {};
	constructor() {
		if (typeof OfficeController.instance !== 'undefined') {
			return OfficeController.instance;
		}
		// @ts-ignore
		this.offices = window.offices;
		this.services = window.services;
		document.addEventListener('change-office', this.changeOfficeHandle.bind(this));
		if (typeof Cookies.get('office') !== 'undefined') {
			this.activeOffice = parseInt(Cookies.get('office') as string, 10);
		} else {
			this.activeOffice = 1;
		}
		OfficeController.instance = this;
	}


	findOfficeFromId(id: number): Office | undefined {
		return  this.offices.find((office: Office) => {
			return office.id === id
		});
	}


	setOffice(id: number) {
		Array.from<HTMLLIElement>(document.querySelectorAll('[data-option-office-id="' + id + '"]')).forEach((item) => {
			item.click()
		});
		// const office = this.findOfficeFromId(id);
		// if (office) {
		// 	this.changeOffice(office)
		// }
	}

	changeOfficeHandle(event: CustomEventInit<EventChangeOffice>) {
		const officeEvent = event.detail?.office ?? '';

		const office = this.offices.find((office: Office) => {
			return office.address.includes(officeEvent);
		});
		if (!office) return undefined;
		this.changeOffice(office)
	}


	changeOffice(office: Office) {

		this.activeOffice = office.id;

		Array.from<HTMLSpanElement>(document.querySelectorAll('[data-content="address-office"]')).forEach((span) => {
			span.innerHTML = office.address[0];
		});
	}

	private setPhones() {
		const { phone } = this.getActiveOffice();
		const formatNumber = getPhoneFormat(phone, 'number');
		const formatText = getPhoneFormat(phone);
		Array.from<HTMLAnchorElement>(document.querySelectorAll('[data-content="phone-office"]')).forEach((link) => {
			link.href = 'tel:' + formatNumber;
			link.innerHTML = formatText;
		});
		Array.from<HTMLAnchorElement>(document.querySelectorAll('[data-content="whatsapp-office"]')).forEach((link) => {
			link.href = 'https://wa.me/' + formatNumber;
		});
		Array.from<HTMLAnchorElement>(document.querySelectorAll('[data-content="viber-office"]')).forEach((link) => {
			link.href = 'viber://add?number=' + formatNumber;
		});
	}
	public get activeOffice(): number {
		return this._activeOffice;
	}

	public getActiveOffice() {
		return this.offices.find((office) => office.id === this.activeOffice) as Office;
	}

	public set activeOffice(value: number) {
		this._activeOffice = value;
		Cookies.set('office', this.activeOffice.toString(), { expires: 30 });
		this.setPhones();
		this.setSpace();
		this.menuLinks();

		if (document.getElementById('main-form-office')) {
			(document.getElementById('main-form-office') as HTMLInputElement).value = this.getActiveOffice().address[0];
		}
	}

	private menuLinks() {
		Array.from<HTMLElement>(document.querySelectorAll('#header .menu-item')).forEach((li) => {
			const link = li.querySelector('a') as HTMLAnchorElement;
			if (link.href) {
				const hash = decodeURI(new URL(link.href).hash ?? '').slice(1);
				const regex = /c([0-9]{1})-([a-z]+)-(.*)/;
				const match = regex.exec(hash);
				if (match) {
					const [, idOffice] = match;
					css(li, {
						display: parseInt(idOffice, 10) === this.getActiveOffice().id ? 'block' : 'none'
					});
				}
			}
		});
	}

	private setSpace() {
		if (document.body.classList.contains('page-template-main')) {
			Array.from<HTMLDivElement>(document.querySelectorAll('.tabs-space, .tabs-price')).forEach((tabs) => {
				css(tabs, {
					display: 'none'
				});
			});
			css(document.querySelector(`.tabs-space[data-office-id="${this.activeOffice.toString()}"]`) as HTMLElement, {
				display: 'block'
			});
			// @ts-ignore
			document.querySelector(`.tabs-price[data-office-id="${this.activeOffice.toString()}"] [data-name]`)!.click();

			css(document.querySelector(`.tabs-price[data-office-id="${this.activeOffice.toString()}"]`) as HTMLElement, {
				display: 'block'
			});
			// @ts-ignore
			document.querySelector(`.tabs-price[data-office-id="${this.activeOffice.toString()}"] [data-name]`)!.click();
		}
	}
}

window.OfficeController = OfficeController;

// // @ts-ignore
// (async function () {
// 	console.clear();
// 	const coworkings = Array.from(document.querySelectorAll('.TabsCoworkingOvervievOne,.TabsCoworkingOvervievTwo'));

// 	const slugTabTitle = {
// 		'Офис #1': 'office-1',
// 		'Офис #2/конференц-зал': 'conference',
// 		'Офис #3': 'office-3',
// 		'Переговорная': 'peregovornaya',
// 		'Private office': 'private-office',
// 		'Обзор': 'review',
// 		'Рабочие места/офисы': 'workplaces',
// 		'Кухня': 'kitchen',
// 		'Meeting Space': 'meeting-Space',
// 		'Кофе-зона': 'coffee-area',
// 		'Спорт-зона': 'gym-area',
// 		'Meeting Room': 'meeting-room',
// 	};
// 	const images = [];
// 	coworkings.forEach((coworking, index) => {
// 		const titles = Array.from(coworking.querySelectorAll('.wpb_tabs_nav li a')).map(a => a.textContent.trim()).map(title => slugTabTitle[title]);
// 		const tabs = Array.from(coworking.querySelectorAll('.wpb_tab'));
// 		for (let i = 0; i < tabs.length; i++) {
// 			const tab = tabs[i];
// 			const imagesTab = Array.from(tab.querySelectorAll('.slides img')).map((img, idx) => {
// 				return {
// 					href: img.src,
// 					download: `coworking-${(index + 1)}-${titles[i]}-${(idx + 1)}.` + img.src.split(/\./).pop()
// 				};
// 			});
// 			images.push(...imagesTab);
// 		}
// 	});

// 	function sleep(number) {
// 		return new Promise((resolve) => {
// 			setTimeout(resolve, number * 1000);
// 		});
// 	}
// 	for await (const image of new Set(images)) {
// 		const a = document.createElement('a');
// 		Object.assign(a, image);
// 		a.click();
// 		await sleep(1);
// 	}
// })();
