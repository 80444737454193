let selects = document.querySelectorAll('.select') as never as HTMLDivElement[];
let openListInSelect = Array.from(selects).map((select) => select.querySelectorAll('li'));
let headerOpenPopup = document.querySelector('.select-pop-up');

Array.from<HTMLDivElement>(selects).forEach((select) => {
	addEventListenerSelect(select);
});

export function addEventListenerSelect(select: HTMLDivElement) {
	if (select.classList.contains('select--init')) {
		return false;
	}
	select.classList.add('select--init');
	let iconArrow = select.querySelector('i.fas')!;

	let selectOpenList = select.querySelector('.select__openlist')!;

	function setInitialValueForDropdownListInContacts() {
		let inputItemHidden: HTMLInputElement | null = document.querySelector('.input__item_hidden');
		let relationSelectValue = document.querySelector('.select.relation__select.input__item .select__container span');
		if (inputItemHidden) {
			inputItemHidden.value = relationSelectValue!.innerHTML;
		}
	}

	setInitialValueForDropdownListInContacts();

	function closeOpenList() {
		selectOpenList.classList.remove('active');
		iconArrow!.classList.remove('fa-chevron');
		iconArrow!.classList.add('fa-chevron-down');
	}


	function close() {
		selectOpenList.classList.toggle('active', false);
		iconArrow!.classList.remove('fa-chevron-down');
		iconArrow!.classList.add('fa-chevron-up');
	}

	function toggleOpenOpenList(event:MouseEvent) {

		const fromSelect: HTMLDivElement | null = event.target.closest('.select__openlist') ?? null;


		console.log(fromSelect)
		if (fromSelect !== null) {
			return false
		} else {
			selectOpenList.classList.toggle('active');
			if (iconArrow.classList.contains('fa-chevron-up')!) {
				iconArrow!.classList.remove('fa-chevron-up');
				iconArrow!.classList.add('fa-chevron-down');
			} else if (iconArrow.classList.contains('fa-chevron-down')!) {
				close()
			}
		}

	}

	Array.from(select.querySelectorAll('li')).forEach((li) => {

		li.addEventListener('click', function (event) {
			close()
			event.preventDefault()
			let selectContainer = li.parentNode!.parentNode!;
			selectContainer.querySelectorAll('.select__openlist').forEach((openListInActiveContainer) => {
				openListInActiveContainer.querySelectorAll('li').forEach((liInActiveContainer) => {
					liInActiveContainer.classList.remove('active');
				});
			});
			li.classList.add('active');
			selectContainer.querySelector('span')!.innerHTML = li!.innerHTML;

			const fromSelect: HTMLDivElement | null = li.closest('[data-select]');
			if (fromSelect) {
				if (fromSelect.dataset.select === 'office') {
					document.dispatchEvent(
						new CustomEvent('change-office', {
							detail: {office: li.innerHTML}
						})
					);
					Array.from<HTMLElement>(document.querySelectorAll('[data-select="office"]')).forEach((container) => {
						if (container !== li.closest('[data-select="office"]')) {
							setActiveSelect(container, li);
						}
					});
				}
				if (fromSelect.dataset.select === 'main-form') {
					setInitialValueForDropdownListInContacts();
					setTimeout(closeOpenList, 1);
				}
				if (/office-([0-9]+):([a-z-]+)/.exec(fromSelect.dataset.select || '')) {
					const match = /office-([0-9]+):([a-z-]+)/.exec(fromSelect.dataset.select || '') as RegExpExecArray;
					const space: HTMLDivElement | null = document.querySelector(
						`.tabs.tabs-price[data-office-id="${match[1]}"] .tab[data-space="${match[2]}"]`
					);
					if (!space) return;
					const pricesTables = Array.from<HTMLDivElement>(space.querySelectorAll('[data-from]'));
					pricesTables.forEach((table) => {
						table.style.display =
							`office-${match[1]}:${match[2]}:${li.dataset.plan}` === table.dataset.from ? 'table' : 'none';
					});
				}
			}
		})
	});
	select.addEventListener('click', toggleOpenOpenList);
}

export function setActiveSelect(container: HTMLElement, active: HTMLLIElement): void {
	container.querySelectorAll('.select__openlist').forEach((openListInActiveContainer) => {
		openListInActiveContainer.querySelectorAll('li').forEach((liInActiveContainer) => {
			liInActiveContainer.classList.remove('active');
		});
	});
	active.classList.add('active');
	container.querySelector('span')!.innerHTML = active.innerHTML;
}

// document.addEventListener('click', (event) => {
// 	const target = event.target as HTMLElement;
// 	if (!target.closest('.select')) {

// 	}
// });
